/** Page Loader */
#loader {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 9;
    place-items: center;
    justify-content: center;
    text-align: center;
    opacity: 1;
  }
  
  @keyframes animate-fade {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  #loader.hide {
    animation-duration: 5s;
    animation-name: animate-fade;
    animation-delay: 1s;
    animation-fill-mode: backwards;
    opacity: 0;
    z-index: -1;
  }
  
  #loader figcaption {
    display: block;
    color: #232323;
    font-size: 18px;
    margin-top: 15px;
    font-weight: bold;
  }
  /** END Page Loader */