.shipping-zones-container {
    width: 80%;
    margin: 15px;
    background: #FFF;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 60vh;
    justify-content: flex-start;
    place-content: flex-start;
}

.shipping-zones-container.full {
    width: 100%;
    margin: 15px;
}

.shipping-zones-container h1 {
    width: 100%;
}

.shipping-zones-container .shipping-zones-input {
    width: 50%;
    padding: 15px;
    padding-left: 0;
}

.shipping-zones-container .shipping-zones-input.full {
    width: 100%;
}

.shipping-zones-input.message{
    width: 100%;
}

.shipping-zones-container ul {
    margin: 0;
    margin-top: 15px;
    padding: 0;
    list-style: none;
}

.shipping-zones-container li {
    line-height: 1.5;
    font-size: 18px;
    margin: 5px 0;
    padding: 5px 0;
    width: 50%;
    display: inline-block;
}

@media screen and (max-width: 768px) {

    .shipping-zones-container h1{
        font-size: 28px;
    }
    
    .shipping-zones-container li {
        width: 100%;
        font-size: 15px;
        border-bottom: 1px solid #eee;
    }
}