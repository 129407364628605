/** Header */
.page-header {
    width: 100%;
    background-color: white;
    /* margin-bottom: 30px; */
    display: flex;
    place-content: center;
    box-shadow: 6px 10px 15px -7px rgba(0,0,0,.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    min-height: 120px;
  }
  
/* .header-black-start {
    background-color: black;
    color: #FFF;
    text-align: center;
    padding: 7px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
} */
  
/* .desktop-header-container {
    width: 1281px;
    max-width: 100%;
    margin: auto;
    padding: 5px 0;
} */
  
.desktop-header{
    flex-wrap: nowrap;
    display: flex;
    place-content: center;
    justify-content: center;
    width: 1366px;
    max-width: 1366px;
}


.brand-image {
    width: 180px;
}

.brand-image img{
    padding: 10px;
    padding-left: 15px;
    margin: 10px 0;
}
  
.desktop-menu {
    display: flex;
    width: calc(100% - 180px);
}
  
.desktop-menu .menu-list {
    list-style: none;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    width: 100%;
}
  
.desktop-menu .menu-list li {
    display: inline-block;
    padding: 10px;
    text-align: center;
}
  
.desktop-menu .menu-list a {
    font-size: 13px;
    color: #e00c14;
    font-weight: 700;
    text-transform: uppercase;
}
  
.desktop-menu .header-search-and-buttons {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: end;
}
  
.desktop-menu .header-search-and-buttons {
    list-style-type: none;
}
  
.desktop-menu .header-search-and-buttons li {
    display: inline-block;
    padding: 5px 10px;
}
  
.desktop-menu .search-box {
    border-radius: 25px;
    border: 1px solid #e00c14;
    font-size: 16px;
    padding: 5px;
    visibility: hidden;
}

.cart-icon-link {
    position: relative;
    display: inline-block;
}

.basket-icon-badge {
    background-color: #000;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 5px;
    position: absolute;
    top: -5px;
    right: -5px;
    border-top-left-radius: 50% 50%; border-top-right-radius: 50% 50%; border-bottom-right-radius: 50% 50%; border-bottom-left-radius: 50% 50%;
}

@media (max-width: 1280px) {

    .desktop-menu .menu-list a {
        font-size: 12px;
    }

    .brand-image img {
        margin-left: 0;
    }

}

@media (max-width: 1024px) {

    .page-header {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .desktop-header{
        display: none;
    }

    .page-header {
        justify-content:stretch;
    }

    .responsive-header {
        display: flex;
        width: calc(100vw - 30px);
        max-width: 100%;
    }

    

    .brand-image {
        width: 170px;
    }

    .brand-image img{
        margin-left: 10px;
        margin-right: 10px;
    }

    .menu-icon img{
        padding:7px;
        /* margin-left: 30px; */
        cursor: pointer;
    }

    .responsive-nav {
        display: flex;
        width: 100%;
    }
      
    .responsive-nav .menu-list {
        list-style: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
      
    /* .responsive-nav .menu-list li {
        display: inline-block;
        padding: 10px;
        text-align: center;
    }
      
    .responsive-nav .menu-list a {
        font-size: 14px;
        color: #FFF;
        font-weight: bold;
        text-transform: uppercase;
    } */

    #responsive-nav-menu {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 115px;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        max-height: calc(100vh - 115px);
        
    }

    #responsive-nav-menu.show {
        z-index: 9;
        background-color: rgba(0,0,0,.8);
        opacity: 1;
        visibility:visible;
    }

    .mobile-nav-link {
        color: #232323;
        font-size: 18px;
        font-weight: 500;
        margin-left: 10px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }

    .mobile-nav-link span {
        font-family: unset;
        font-size: 18px;
        font-weight: 500;
    }

    /* #responsive-nav-menu ul{
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #fff;
        max-height: calc(100vh - 115px);
        overflow-y: auto;
    }

    #responsive-nav-menu ul li{ 
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding-left: 15px;
    }

    #responsive-nav-menu ul li h3{
        padding: 20px 15px;
        text-align: left;
    }

    #responsive-nav-menu ul li a{
        width: 100%;
        padding: 20px 15px;
        font-size: 18px;
        color: #232323;
        display: block;
        text-align: left;
        font-weight: bold;
    }

    #responsive-nav-menu ul li.category-link{
        padding-left: 40px;
    } */

    .menu-icon-spacer {
        width: 20px;
    }

}


@media (min-width: 1025px) {
    
    .responsive-header {
        display: none;
    }

    #responsive-nav-menu {
        display: none;
    }
    
}