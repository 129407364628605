/** Footer */
.page-footer {
    background-color: #232323;
    padding: 30px;
    /* margin-top: 30px; */
    width: 100%;
  }
  
  .footer-content {
    width: 1366px;
    max-width: 100%;
    margin: auto;
    display: flex;
    color: #fff;
    font-size: 14px;
    line-height: 1.8;
  }
  
  .footer-brand-image {
    width: 30%;
  }
  
   .directions {
     width: 40%;
   }
  
   .terms {
     margin-top: 10px;
     font-weight: bold;
   }
  
   .footer-red-end {
     background-color: #232323;
     color: #FFF;
     text-align: center;
     font-size: 12px;
     padding: 5px 0;
     margin-top: 15px;
     font-weight: bold;
     padding: 15px;
   }

   .page-footer a {
     color: #FFF;
   }

   @media (max-width: 1024px) {

    .page-footer .footer-content {
      flex-wrap: wrap;
      font-size: 14px;
    }
    
    .page-footer .directions {
       padding: 15px;
       width: 100%;
     }

     .page-footer .footer-text {
      padding: 15px;
    }

    .page-footer .terms {
      width: 100%;
      padding: 15px;
    }

    .page-footer .footer-red-end {
      text-align: left;
      font-size: 12px;
    }

   }

   @media (max-width: 768px) {
    .page-footer .terms {
      padding: 3px 15px;
    }
   }