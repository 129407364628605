.contact-container {
    width: 80%;
    margin: 15px;
    background: #FFF;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 60vh;
    justify-content: flex-start;
    place-content: flex-start;
}

.contact-container.full {
    width: 100%;
    margin: 15px;
}

.contact-container h1 {
    width: 100%;
}

.contact-container .contact-input {
    width: 50%;
    padding: 15px;
    padding-left: 0;
}

.contact-container .contact-input.full {
    width: 100%;
}

.contact-input.message{
    width: 100%;
}

@media (max-width: 768px) {
    .contact-container {
        width: 100%;
        margin: 15px;
        background: #FFF;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        min-height: 60vh;
        justify-content: flex-start;
        place-content: flex-start;
    }

    .contact-container.full {
        width: 100%;
        margin: 15px;
    }

    .contact-container h1 {
        width: 100%;
    }

    .contact-container .contact-input {
        width: 100%;
        padding: 15px;
        padding-left: 0;
    }

    .contact-container .contact-input.full {
        width: 100%;
    }

    .contact-input.message{
        width: 100%;
    }
}