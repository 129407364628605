/** Product Catalog */
.catalog-container {
    width: 75%;
    margin: 15px;
}
  
.cart-container {
  width: 25%;
  margin: 15px 0;
  margin-right: 15px;
  /* padding: 0 15px;
  background-color: #fff; */
}


@media (max-width: 1280px) {

  /** Product Catalog */
  .catalog-container {
    width: 100%;
    margin: 15px;
  }

  .cart-container {
    width: 0%;
    margin: 30px 0;
    display: none;
  }

}


  
  .product-catalog {
    /* display: flex; */
    /* gap: 15px; */
    /* flex-wrap: wrap; */

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
  }
  
  /** Product Boxes */
  .product-box {
    /* width: calc(25% - 15px); */
    border: 1px solid #ccc;
    background-color: #fff;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px; */
    position: relative;
  }
  
  .product-box .img-responsive {
    display: none;
  }

  .product-image {
    width: 100%;
    position: relative;
  }
  
  .product-image img {
    width: 100%;
    object-fit: cover;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
  }

  /* .product-image .cooking-options li:first-child img{
    border-top-left-radius: 15px;
  } */

  /* .product-image .cooking-options img{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  } */
  
  .cooking-options {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .cooking-options img {
    object-fit: initial;
  }
  
  .cooking-options li {
    margin: 1px 0;
    width: 50px;
  }
  
  .product-title h4{
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    padding: 3px;
    padding-top: 10px;
    min-height: 50px;
  }
  
  .product-price h5{
    font-size: 25px;
    color: #e00c14;
    text-align: center;
    margin: 15px 0;
    font-weight: bold;
    min-height: 130px;
  }

  .measure-unit {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 16px;
    color: #000;
  }


  @media (max-width: 768px) {

    .product-box .img-responsive {
      display: block;
    }

    .product-catalog {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .product-box .img-desktop {
      display: none;
    }

    .product-title h4{
      text-align: center;
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      padding: 3px;
      height: fit-content;
      min-height: fit-content;
    }
    
    .product-price h5{
      font-size: 18px;
      color: #e00c14;
      text-align: center;
      margin: 15px 0;
      font-weight: bold;
    }

    .product-price h5{
      min-height: 100px;
    }

  }