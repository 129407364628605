/** Checkout */
.checkout-container {
    background: #FFF;
    width: 60%;
    margin: 15px 0;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    min-height: 60vh;
    justify-content: flex-start;
    place-content: flex-start;
    margin-left: 15px;
  }

  .checkout-container.full {
    background: #FFF;
    width: 100%;
    margin: 15px;
  }
  
  /* .checkout-container h1 {
    width: 60%;
  } */
  
  .checkout-cart-container {
    width: calc(40% - 30px);
    padding: 15px;
    margin-right: 15px;
  }




  .checkout-container .add-to-cart {
    display: none;
  }

  .checkout-container .checkout-input {
    width: 50%;
    padding: 15px;
    padding-left: 0;
  }

  .checkout-input.references{
    width: 100%;
  }

  
  
/*   .checkout-container .checkout-input label {
    font-size: 17px;
    font-weight: bold;
  }

  .checkout-container .checkout-input input[type="text"]{
    width: 100%;
    padding: 15px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 7px;
  } */

  
/*   .checkout-container .checkout-input select{
    width: 100%;
    padding: 15px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 7px;
    margin: 5px;
  } */
  
  .checkout-buttons {
    width: 25%;
    margin: 15px 0;
    padding: 15px;
  }

  

  .checkout-buttons #finish-order{
    background-color: #000;
  }
  
  .checkout-buttons a {
    display: block;
    background-color: #232323;
    border: 1px solid #232323;
    padding: 15px;
    font-size: 18px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .checkout-container .checkout-buttons {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }
  
   .checkout-container .checkout-buttons a {
    margin-left: 0;
    display: block;
  }
  
  .checkout-container .checkout-product-list {
    padding: 5px 15px;
    padding-bottom: 15px;
    padding-left: 0;
    width: 100%;
    /* background-color: #FFF;
    border: 1px solid #ddd; */
  }
  
  .checkout-container .checkout-product-list .circular--square {
    width: 50px;
    height: 50px;
  }
  
  .checkout-container .checkout-product-list h2 {
    margin: 0;
    margin-bottom: 30px;
  }
  
  .checkout-container .checkout-product-list h3{
    font-size: 18px;
    /* text-transform: uppercase; */
  }
  
  .checkout-container .checkout-product-list h5{
    font-size: 14px;
  }
  
  .checkout-container .checkout-product-list p{
    font-size: 18px;
    margin: 15px 0;
  }
  
  .checkout-container .checkout-product-list a {
    display: none;
  }
  
  .checkout-container .checkout-product-list .side-cart-row .remove-from-cart {
    display: none;
  }
  
  .checkout-container .checkout-product-list .side-cart-row {
    place-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;;
  }

  /* Checkboxes */
  .checkbox {
    width: 100%;
  }

  .checkbox label{
    width: 100%;
    display: block;
    font-size: 18px;
    font-weight: bold;
  }

  .checkbox span {
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: inline-block;
  }

  .checkbox input {
    width: auto;
  }

  .button.go-to-orders{
    width: fit-content;
  }

  /** Login Message */
  h2.checkout-login-message {
    font-size: 24px;
    width: 100%;
    display: block;
  }

  p.checkout-login-message {
    font-size: 16px;
    width: 100%;
    display: block;
    margin: 15px 0;
    text-transform: none;
  }

  #checkout-confirm-adress-buttons {
    width: 100%;
    display: flex;
    place-content: center;
  }

  #checkout-confirm-adress-buttons ul {
    margin: 0;
    padding: 0;
    width: 50%;
    list-style: none;
  }

  #checkout-confirm-adress-buttons button {
    background-color: #000;
  }

  .last-address-select {
    font-weight: bold;
  }

  div.checkout-input.last-address-select{
    width: 100%;
  }


  .checkout-container p.cart-product-detail{
    font-size: 14px;
  }

  @media (max-width: 768px) {

    .checkout-container {
      width: calc(100% - 30px);
    }

    .checkout-container.full {
      margin-left: 15px;
      margin-right: 15px;
    }

    .checkout-container.checkout-cart-container{
      margin-top: 0;
    }

    .checkout-container h1 {
      font-size: 28px;
    }

    .checkout-container h2 {
      font-size: 20px;
    }

    .checkout-cart-container {
      width: calc(100% - 30px);
    }

    .checkout-container .checkout-input {
      width: 100%;
    }

    .checkout-buttons {
      width: 100%;
    }

    #checkout-confirm-adress-buttons {
      width: 100%;
    }

    .pago-title {
      display: none;
    }

    .checkout-product-list .cart-product-title h5{
      width: 100%;
      font-size: 16px;
      margin-bottom: 15px;
      text-transform: none;
    }

    .checkout-product-list .total-price-row h3{
      font-size: 18px;
      text-transform: none;
    }

    .checkout-container button{
      font-size: 16px;
    }
    
  }


.checkout-input.preselected-delivery-address p{
  font-size: 13px;
  font-style: italic;
  line-height: 1.6;
  margin-left: 5px;
  border: 1px solid #ddd;
  padding: 5px 10px;
}