.site-information-container {
    width: 80%;
    margin: 30px 0;
    background: #FFF;
    margin: 15px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    min-height: 60vh;
    justify-content: flex-start;
    place-content: flex-start;
}

.site-information-container.full {
    width: 100%;
}

.site-information-container h1 ,
.site-information-container h2 {
    width: 100%;
}

.site-information-container h2 {
    margin: 15px 0;
    font-weight: 500;
}

.site-information-container p {
    line-height: 1.5;
    font-size: 18px;
    text-align: justify;
}
