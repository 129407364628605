.order-container {
  width: 80%;
  background: #FFF;
  margin: 15px;
  /* margin-right: 15px; */
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  min-height: 60vh;
  justify-content: flex-start;
  place-content: flex-start;
 }

 .order-container.full {
   width: 100%;
 }

 .order-container h1 {
   font-size: 28px;
 }

.order-list {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
  }
  
  .order-list li {
    /* margin: 10px 0; */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px 10px;
    font-size: 18px;
    cursor: pointer;
  }
  

  .order-container.skeleton .order-list li {
    margin: 0;
    padding: 0;
  }

  /* .order-detail-btn {
    float: right;
  } */

  .order-detail-btn button {
      padding: 3px;
      background-color: transparent;
      color: #000;
      margin: 0;
      border: 0;
  }

  #order-detail-list {
    list-style-type: none;
    width: 100%;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  #order-detail-list dt{
    width: 30%;
    text-align: left;
    padding: 10px 5px;
    font-weight: bold;
  }

  #order-detail-list dd {
    padding: 10px 5px;
    width: calc(70% - 40px);
  }

  #order-detail-list dl:after {
    content:"";
    display:table;
    clear:both;
  }

  #order-detail-product-list {
    border: 1px solid #eee;
    width: 100%;
  }

  #order-detail-product-list th{
    text-align: left;
    font-size: 13px;
    padding: 5px;
    background-color: #eee;
  }

  #order-detail-product-list td {
    font-size: 13px;
    padding: 15px 5px;
  }

  #btn-duplicate-order {
    display: block;
    padding: 15px;
    font-size: 18px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    background-color: black;
    border: 1px solid black;
    width: 100%;
    border-radius: 0;
    margin-top: 30px;
  }

  .order-container.order-details .buttons {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }

  .order-container.order-details .buttons a {
    width: calc(100% - 10px);
    display: block;
  }

  @media (max-width: 768px) {

    .order-container {
      width: 100%;
    }

    .order-list li {
      font-size: 16px;
    }

    .order-container.order-details .buttons {
      flex-wrap: wrap;
    }

    #order-detail-list dt{
      width: 100%;
      padding-left: 0;
    }
  
    #order-detail-list dd {
      width: calc(100% - 40px);
      padding-left: 0;
      margin-left: 0;
    }
  }


.past-orders-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.past-orders-item .detail {
  width: calc(100% - 40px);
}

.past-orders-item .buttons {
  width: 40px;
}

.past-orders-item h4 {
  width: 100%;
  display: flex;
  align-content: center;
}

.past-orders-item ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.past-orders-item li {
  padding: 3px 5px;
  padding-top: 10px;
  padding-left: 0;
  margin: 5px;
  margin-left: 0;
}

.past-orders-item li > span.prd-list-item {
  border: 1px solid #ddd;
  padding: 2px;
  margin: 8px;
  margin-left: 0;
  background-color: #eee;
  font-size: 12px;
  line-height: 1.6;
}

.past-orders-item .spacer {
  height: 5px;
}

@media (max-width: 768px) {
  .past-orders-item li > span.prd-list-item {
    width: 100%;
    display: block;
  }
}