.auth-container {
    width: 100%;
    max-width: 100%;
    margin: 15px 0;
    margin-bottom: 0;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 65vh;
    justify-content: center;
    place-content: center;
}

.auth-container.signup {
  width: 100%;
  min-height: auto;
  /* background-color: #fff; */
  margin: 0;
}

.auth-container.white {
    background: #FFF;
    border: 1px solid #ddd;
}
  
.auth-container h1 {
  width: 100%;
}
  
.auth-container .add-to-cart {
  display: none;
}

.auth-container .checkout-input {
  width: 50%;
  padding: 15px;
  padding-left: 0;
}

h2.auth-login-message {
  font-size: 24px;
  width: 100%;
  display: block;
  text-align: center;
}

  p.auth-login-message {
    text-align: center;
    font-size: 16px;
    width: 100%;
    display: block;
    margin: 15px 0;
    text-transform: none;
  }

  .btn-login {
    border-radius: 7px;
    background-color: #232323;
    color: #fff;
    border-color: #232323;
  }

  .btn-register.light {
    border-radius: 7px;
    background-color: white;
    color: #232323;
    border: 1px solid #232323;
    border-radius: 7px;
  }


  .btn-register {
    border-radius: 7px;
    background-color: #232323;
    color: #fff;
    border-color: #000;
    display: block;
    padding: 15px;
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
  }

  .auth-container .input-login label {
    font-size: 18px;
    font-weight: bold;
  }

  .auth-container .input-login input[type="text"]{
    width: 100%;
    padding: 15px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 7px;
  }

  .social-login {
    margin-bottom: 25px;
  }

  .btn-forgotten-password {
    margin-top: 15px;
    background-color: #fff;
    color: #232323;
    border-color: #fff;
    font-size: 16px;
  }

  #login-options-container { 
    margin-top: 15px;
    width: 360px;
    padding: 30px;
    background-color: #fff;
    /* border-radius: 7px; */
    border: 1px solid #ccc;
  }

  .auth-container.register {
    border-radius: 7px;
  }

  .reponsive-signup-link-container {
    margin-top: 30px;
  }

  .reponsive-signup-link-container h4 {
    text-align: center;
    font-weight: 500;
  }

  @media (max-width: 1366px) {

    #login-options-container {
      background-color: #fff;
      border: 1px solid #eee;
      margin: 0;
      padding: 15px;
    }

    #login-options-container h4 {
      text-align: center;
      padding: 5px;
    }

    .reponsive-signup-link-container {
      margin-top: 10px;
    }

    .auth-container.login.register{
      display: none;
    }

  }


.auth-container.signup h1{
  text-align: center;
  margin-bottom: 15px;
  text-transform: none;
  font-size: 24px;
}

#custom-registration-form {
  /* border-radius: 7px; */
  background-color: white;
  border: 1px solid #eee;
  padding: 15px;
}

#custom-registration-form.progress {
  min-height: 40vh;
}

#custom-registration-form h2 {
  font-size: 20px;
  margin-bottom: 30px;
}