.empty-cart-message{
    font-weight: 500;
    font-size: 20px;
    /* text-transform: uppercase; */
    text-align: center;
    padding: 15px;
    margin: 5px;
    color: #232323;
}

.empty-cart-message-duplicate-order {
    text-align: center;
}

.button.home.reverse.duplicate-order {
    width: 100%;
}

/** Shopping cart */
.side-cart-title {
    margin: 30px 0;
    font-size: 18px;
    /* text-transform: uppercase; */
}

.side-cart {
    list-style-type: none;
    width: 100%;
    margin-bottom: 50px;
}
  
.side-cart li {
    margin: 10px 0;
}
  
.side-cart .side-cart-row {
    display: flex;
    flex-wrap: wrap;
    place-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}
  
.side-cart .side-cart-row img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
  
.side-cart .cart-product-title {
    width: calc(100% );
}
  
.side-cart .side-cart-row h5 {
    font-weight: 500;
    font-size: 14px;
    /* text-transform: uppercase; */
}
  
.side-cart .side-cart-row .remove-from-cart {
    width: 130px;
    display: flex;
    place-content: end;
    
}
  
.side-cart .side-cart-row .remove-from-cart button {
    cursor: pointer;
    width: fit-content;
    background-color: white;
    /* border-radius: 50%; */
    border: 0;
    padding: 5px 9px;
    color: #232323;
    font-size: 16px;
    font-weight: bold;
    margin-left: 0;
}

.side-cart .side-cart-row .remove-from-cart button:hover {
    background-color: #232323;
    color: white;
}

@media (max-width: 1024px) {
    .side-cart .side-cart-row .remove-from-cart {
      width: 50px;
    }
  }


.side-cart .side-cart-row .add-to-cart ul{
    margin-top: 5px;
    place-items: center;
    width: 150px;
}

.side-cart .side-cart-row .add-to-cart .add-to-cart-quantity {
    
    padding: 2px;
    margin:0;
}

.side-cart .side-cart-row .add-to-cart li {
    margin: 0;
    width: 33.3%;
}

.side-cart .side-cart-row .add-to-cart li button{
    
    padding: 2px;
    margin-left: 0;
    margin: 0;
}

.side-cart .side-cart-row .add-to-cart .basket-icon{
    display: none;
}


/* .side-cart-total h3{
    font-size: 24px;
    text-transform: uppercase;
} */

.side-cart-total p {
    font-size: 17px;
    margin: 15px 0;
    line-height: 1.5;
}

.side-cart-total .checkout-button {
    margin-left: -10px;
}

.total-price-row {
    display: flex;
    flex-wrap: wrap;
    place-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.side-cart-total .total-price-row h3 {
    width: 50%;
}

.side-cart-total .price{ 
    color: #232323;
    font-weight: 700;
    text-align: right;
}

 /** Catalog page cart style */
 .cart-container.catalog .side-cart-container {
     border: 1px solid black;
     background-color: white;
     border: 1px solid #eee;
     padding: 15px;
     padding-top: 0;
 }

 .cart-product-detail{
    display: block;
    width: 100%;
    font-size: 14px;
    margin: 10px 0;
}

@media (max-width: 768px) {
   .cart-product-detail{
       font-size: 14px;
   }
}