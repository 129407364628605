/** Cart */
.cart-page-container {
    background: #FFF;
    width: calc(75% - 15px);
    margin: 15px 0;
    margin-left: 15px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    min-height: 60vh;
    justify-content: flex-start;
    place-content: flex-start;
  }
  
.cart-page-buttons {
    width: calc(25% - 30px);
    margin: 30px 0;
    margin-top: 0;
    margin-left: 15px;
    padding-top: 25px;
}

.cart-page-container .checkout-button {
    display: none;
}

.cart-page-container {
    padding: 15px;
}
  
.cart-page-container .circular--square {
    width: 50px;
    height: 50px;
}
 
.cart-page-container .empty-cart-message {
  text-align: left;
  margin-left: 0;
  padding-left: 0;
}

.cart-page-container h2 {
    margin: 0;
    margin-bottom: 30px;
    width: 100%;
}

.cart-page-container #side-cart-duplicate-order-wrapper{
  display: none;
}
  
.cart-page-container h3{
    font-size: 18px;
    /* text-transform: uppercase; */
  }
  
  .cart-page-container h5{
    font-size: 14px;
  }
  
  .cart-page-container .aprox-values-info{
    font-size: 18px;
    margin: 15px 0;
  }
  
  .cart-page-container a {
    display: none;
  }
  
   
  .cart-page-container .side-cart-row {
    place-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;;
    
  }

  .cart-page-container .cart-product-title {
    display: flex;
  }

  .cart-page-container .cart-product-title h5{
    width: 50%;
  }

  .cart-page-container .add-to-cart {
    width: calc(100% - 100px);
  }

  @media (max-width: 1280px) {
    .cart-page-container .cart-product-title h5{
      width: 100%;
      font-size: 16px;
      margin-bottom: 15px;
    }

    .cart-page-container .add-to-cart {
      width: calc(100% - 50px);
    }
  }

  @media (max-width: 768px) {
    .cart-page-container {
      margin: 15px;
      width: calc(100% - 15px);
    }

    .cart-page-container h1 {
      font-size: 28px;
    }

    .cart-page-container h2 {
      /* margin-top: 10px; */
      font-size: 20px;
    }
    
    .cart-page-container h3{
      font-size: 20px;
      /* text-transform: uppercase; */
    }
    
    .cart-page-container .sidecart-go-to-checkout {
      font-size: 16px;
    }

    .cart-page-buttons {
      display: none;
    }

    .side-cart-row {
      flex-wrap: wrap;
    }

    .cart-page-container .cart-product-title h5{
      width: 100%;
      font-size: 16px;
      margin-bottom: 15px;
    }

    .cart-page-container .add-to-cart {
      width: calc(100% - 50px);
    }

    .cart-page-container .aprox-values-info{
      font-size: 16px;
    } 

    .cart-page-container .checkout-button {
      display: block;
    }

    .cart-page-container #side-cart-duplicate-order-wrapper{
      display: block;
    }

    .cart-page-container #side-cart-duplicate-order-wrapper p {
      text-align: left;
    }

  }