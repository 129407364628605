/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@import url(./styles/header.css);

@import url(./styles/home.css);
@import url(./styles/auth.css);
@import url(./styles/catalog.css);
@import url(./styles/checkout.css);
@import url(./styles/cart.css);
@import url(./styles/orders.css);
@import url(./styles/account.css);

@import url(./styles/site-info.css);
@import url(./styles/contact.css);
@import url(./styles/shipping-zones.css);

@import url(./styles/shoppingCartProducts.css);

@import url(./styles/loginComponent.css);

@import url(./styles/footer.css);
@import url(./styles/loader.css);

@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #eee;
}

#content-wrapper {
  margin-top: 135px;
}

h1, h2, h3, h4, h5, h6, p, ul, img {
  padding: 0;
  margin: 0;
}

.form-title {
  font-size: 20px;
}

a {
  text-decoration: none;
}

label {
  font-size: 16px;
  font-weight: bold;
}

input[type="text"],
input[type="password"],
input[type="email"]{
  width: 100%;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 7px;
  margin: 5px;
}


button {
  display: block;
  padding: 15px;
  font-size: 18px;
  color: #FFF;
  text-align: center;
  font-weight: bold;
  /* text-transform: uppercase; */
  margin: 5px;
  background-color: black;
  border: 1px solid black;
  width: 100%;
  border-radius: 7px;
  cursor: pointer;
}

select{
  width: 100%;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 7px;
  margin: 5px;
}

a.button,
.checkout-button a,
.checkout-button button{
  display: block;
  padding: 15px;
  font-size: 18px;
  color: #FFF;
  text-align: center;
  font-weight: bold;
  /* text-transform: uppercase; */
  margin: 5px;
  background-color: black;
  border: 1px solid black;
  width: 100%;
  border-radius: 7px;
  cursor: pointer;
}

a.button {
  margin-top: 15px;
  background-color: #232323;
  color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}

a.button.reverse,
button.reverse {
  background-color: #FFF;
  color: #000;
  border: 1px solid #232323;
  cursor: pointer;
}

.circular--square { border-top-left-radius: 50% 50%; border-top-right-radius: 50% 50%; border-bottom-right-radius: 50% 50%; border-bottom-left-radius: 50% 50%; }
.circular--square img { border-top-left-radius: 50% 50%; border-top-right-radius: 50% 50%; border-bottom-right-radius: 50% 50%; border-bottom-left-radius: 50% 50%; }


/** Components */

.warning-info {
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
  border: 2px solid red;
  width: 100%;
}



/** Imagen circular */
.spacer {
  width: 100%;
  display: block;
  height: 50px;
}

.spacer.h30 {
  height: 30px;
}

/* .circular--square {
  border-radius: 50%;
} */

/** Link button style */
.button {
  /* background-color: red; */
  color: white;
  font-size: 18px;
  padding: 7px 30px;
  margin: 2px;
  border-radius: 25px;
  display: inline-block;
  /* text-transform: uppercase; */
  font-weight: bold;
  cursor: pointer;
}

/* .button.reverse {
  color: #232323;
  border: 1px solid #666;
} */
/** Link button style */


/** Add to cart Component */
.add-to-cart ul {
  list-style-type: none;
  display: flex;
  width: 100%;
}

.product-box .add-to-cart ul{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.add-to-cart ul li {
  width: 25%;
  border: 1px solid #ccc;
  text-align: center;
  align-content: center;
}

/* .product-box .add-to-cart li:first-child {
  border-bottom-left-radius: 15px;
} */

.add-to-cart button {
  padding: 10px;
  text-align: center;
  color: #232323;
  font-weight: bold;
  font-size: 18px;
  background-color: white;
  border: 0;
  margin-left: 0;
}

.add-to-cart .add-to-cart-quantity{
  padding: 10px;
  display: inline-block;
}

.add-to-cart .basket-icon {
  background: white;
  display: inline-block;
  padding: 5px;
  border-color: #ccc;
  /* border-bottom-right-radius: 15px; */
  cursor: pointer;
}

.add-to-cart .basket-icon span {
  display: none;
}

@media (max-width: 768px) {

  .product-box .add-to-cart ul li {
    width: 0%;
    border: 0;
  }

  .product-box .add-to-cart li button,
  .product-box .add-to-cart .add-to-cart-quantity {
    display: none;
  }

  .product-box .add-to-cart .basket-icon {
    width: 80%;
    border: 1px solid #e00c14;
    border-radius: 15px;
    padding: 5px;
    margin: auto;
    margin-bottom: 15px;
    /* display: none; */
  }

  .product-box .add-to-cart .basket-icon img {
    max-height: 20px;
    float: left;
  }

  .product-box .add-to-cart .basket-icon span {
    display: inline-block;
    margin-left: -15px;
    font-weight: bold;
    font-size: 14px;
  }

}

/** End of Add to cart Component */



/** Common */
.content-wrapper {
  width: 1366px;
  max-width: 100%;
  margin: auto;
  display: flex;
  min-height: 768px;
}


@media (max-width: 768px) {
  .content-wrapper {
    flex-wrap: wrap;
    min-height: unset;
  }
}



/** Authentication */
/* #custom-registration-form {
  margin-left: 15px;
} */


/** Sliders **/
button.image-gallery-bullet {
  width: fit-content;
}


@keyframes scaling {
  From {
      transform: scale(1.0);
  }
  To {
      transform: scale(1.06);
  }
}

.scaleupanddown{
  animation: scaling 1s infinite alternate;
}