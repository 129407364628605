/** Home Page */
.home-content {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;
  }

  .home-options-content {
    width: 1280px;
    max-width: 100%;
    margin: auto;
    min-height: 274px;
  }
  
  .home-content h1 {
    font-size: 3rem;
    width: 70%;
  }
  
  .home-sliders {
    min-height: 80vh;
    max-width: 100%;
    background-color: red;
  }
  
  .home-sliders img{
    width: 100%;
    object-fit: cover;
  }
  
  /** sliders buttons*/
  .home-options {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-content: center;
  }

  .home-options-content > h1 {
    min-height: 150px;
  }
  
  .home-sliders-menu {
    margin-top: 50px;
    list-style-type: none;
  }
  
  .home-sliders-menu li {
    display: inline;
    padding: 5px;
  }

  a.button.home {
    display: inline-block;
    width: fit-content;
    text-transform: none;
    background-color: #e00c14;
    border: 0;
    text-transform: uppercase;
    font-size: 18px;
  }

  a.button.home.reverse {
    border: 0;
  }


  @media (max-width: 1366px) {

    .home-content h1 {
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0;
    }
    
    .home-sliders-menu {
        padding: 15px;
    }

  }

  @media (max-width: 540px) {
    .home-content {
      height: fit-content;
      min-height: 350px;
    }

    .home-sliders {
      min-height: 420px;
    }

    .home-options-content {
      width: 100%;
      min-height: unset;
    }

    .home-content h1 {
      font-size: 26px;
      width: 100%;
      max-width: 100%;
      padding-top: 40px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 0;
      min-height: 200px;
      display: block;
    }

    .home-sliders-menu {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .home-sliders-menu li {
      display: inline-block;
      width: 50%;
    }

    a.button.home {
      font-size: 12px;
      display: inline-block;
      width: 100%;
      margin: 0 5px;
    }
  }