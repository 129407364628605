/** Account Page */


.account-container {
    width: 80%;
    background: #FFF;
    margin: 15px;
    /* margin-right: 15px; */
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    min-height: 60vh;
    justify-content: flex-start;
    place-content: flex-start;
   }

   .account-container.full {
    width: 100%;
    margin: 15px;
   }
  
   .account-menu {
    width: 20%;
    margin: 15px 0;
    min-height: 60vh;
   }
  
   .account-menu ul {
     margin: 0;
     padding: 0;
     list-style: none;
   }
  
   .account-menu ul li {
     padding: 0 10px 20px 15px;
     font-size: 18px;
   }
  
   .account-menu ul li a {
     color: #232323;
   }
  
   .account-container .checkout-input {
    width: 100%;
    padding: 15px;
    padding-left: 0;
  }
  
  /* .account-container .checkout-input label {
    font-size: 17px;
    font-weight: bold;
  } */


.incomplete-user-data-warning {
    margin: 15px 0;
    padding: 5px;
}

.account-data-input {
    width: 50%;
    padding: 15px;
    padding-left: 0;
}

/* .account-data-input label {
    font-size: 18px;
    font-weight: bold;
} */

/* .account-data-input input[type="text"]{
    width: 100%;
    padding: 15px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 7px;
} */

/* .account-container button {
    display: block;
    padding: 15px;
    font-size: 18px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    background-color: black;
    border: 1px solid black;
    width: 100%;
    border-radius: 7px;
} */

@media (max-width: 768px) {

    .account-container{
        width: 100%;
        margin: 15px;
    }

    .account-container h1 {
        font-size: 28px;
    }

    .account-container h2 {
        font-size: 20px;
    }

    .account-container p {
        margin: 0;
        margin-bottom: 15px;
    }

    .account-menu {
        width: 100%;
        min-height: unset;
        display: none;
    }

    .account-container .account-data-input{
        width: 100%;
    }

    .account-container button {
        font-size: 16px;
    }

}